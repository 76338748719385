import React, { useState, useRef } from 'react'
import cn from "classnames"
import './nkoform.scss'
import Select from 'react-select';

const options = [
	{value: 'Мигранты', label: 'Мигранты'},
	{value: 'ЛГБ', label: 'ЛГБ'},
	{value: 'ЛЖВ', label: 'ЛЖВ'},
	{value: 'Онкопациенты', label: 'Онкопациенты'},
	{value: 'Люди употребляющие наркотики', label: 'Люди употребляющие наркотики'},
	{value: 'Люди с инвалидностью', label: 'Люди с инвалидностью'},
	{value: ' Гендерная тематика', label: 'Гендерная тематика'},
]

const encode = (data) => {
	return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
}
const NkoForm = (props) => {
	const [formValue, setFormValue] = useState({})
	const [selectValue, setSelectValue] = useState(null)
	const [isFormSended, setIsFormSended] = useState(false)
	const [formStatus, setFormStatus] = useState('')
	const refSelect = useRef(null)

	const handleChange = (e) => {
		e.persist();
		setFormValue(values => ({ ...values, [e.target.name]: e.target.value }));
	}
	const handleSubmit = (e) => {
		if (!selectValue) {
			refSelect.current.focus()
			e.preventDefault()
			return
		}
		setIsFormSended(true)
		let santizeValue = [];
		selectValue.map(item => santizeValue.push(item.label))
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({ "form-name": "nko-contacts-form", "groupSpec": santizeValue, ...formValue })
		})
			.then(() => {setFormStatus('success')})
			.catch(error => {setFormStatus(error)});
		e.preventDefault()
	}
	return (
		<>
		<form action="/" name="nko-contacts-form" 
			data-netlify="true" 
			onChange={handleChange} 
			onSubmit={handleSubmit} 
			className="b-lesson__card b-lesson-card b-form b-nkoform " id="nkoform">
			<div className={cn("b-nko-form--result", {
				"b-nko-form--result--show": formStatus
			})}>
				{
					formStatus === "success" 
						? <h2>Спасибо! <br/> Мы получили вашу заявку. <br/> Информация появится на сайте после рассмотрения администратором. </h2>
						: <h2>Упс. Что-то пошло не так! <br/> Свяжитесь с <a href={`mailto:events@ibb.by?subject=${formStatus}`}>техподдержкой</a>, ребята все поправят. <br/></h2>
				}
			</div>
			
			<h2>Вы можете добавить данные своей организации </h2>
			
			<div className="b-nkoform__group">
				<label htmlFor="name" className="b-label b-label--requared b-nkoform__label">Название организации</label>
				<input 
					className="b-input b-nkoform__input"
					type="text" 
					name="name" 
					id="name" 
					placeholder="Johannes Rau International Centre for Education and Exchange"
					required 
				/>
			</div>
			<div className="b-nkoform__group">
				<label htmlFor="groupSpec" onClick={() => refSelect.current.focus()} className="b-label b-label--requared">С какими группами вы работаете</label>
				<Select
					className="b-select"
					classNamePrefix="b-select"
					value={selectValue}
					onChange={(value) => setSelectValue(value)}
					options={options}
					isMulti={true}
					placeholder="Выберите из списка"
					id="groupSpec"
					name="groupSpec"
					ref={refSelect}
				/>
			</div>
			<div className="b-nkoform__group">
				<label htmlFor="nko-email" className="b-label b-nkoform__label">E-mail</label>
				<input 
					className="b-input b-nkoform__input"
					placeholder="email@example.com"
					type="text" 
					name="nkoEmail" 
					id="nko-email" 
				/>
			</div>
			
			
			<div className="b-nkoform__group">
				<label htmlFor="nko-phone" className="b-label b-nkoform__label">Телефон</label>
				<input 
					className="b-input b-nkoform__input"
					placeholder="+555 555 55 55"
					type="phone" 
					name="nkoPhone" 
					id="nko-phone" 
				/>
			</div>
			<div className="b-nkoform__group">
				<label htmlFor="nko-website" className="b-label b-nkoform__label">Сайт</label>
				<input 
					className="b-input b-nkoform__input"
					placeholder="www.example.com"
					type="text" 
					name="nkoWebsite" 
					id="nko-website"
				/>
			</div>
			
			{/* <div className="b-nkoform__group">
				<label htmlFor="nko-address" className="b-label b-nkoform__label">Физический адрес организации</label>
				<input 
					className="b-input b-nkoform__input"
					placeholder="РБ, г Минск, ул Солидарности 1 офис 24"
					type="text" 
					name="nkoAddress" 
					id="nko-address" 
				/>
			</div>
			
			<div className="b-nkoform__group">
				<label htmlFor="info" className="b-label b-nkoform__label">Дополнительная информация</label>
				<textarea name="info" id="info" className="b-textarea b-nkoform__textarea">
					
				</textarea>
			</div> */}
		</form>
		 <button 
			className="b-button b-button--quiz b-lesson__button" 
			style={{opacity: formStatus !== '' ? 0 : 'inherit',  pointerEvents: formStatus !== "" ? 'none' : 'all'}}
			type="submit" 
			form="nkoform"
			disabled={isFormSended && formStatus === ''}
		>
			Отправить заявку
		</button>
		</>
	)
}

export default NkoForm
