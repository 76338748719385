import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import LessonCard from '../components/Lesson/LessonCard'
import Seo from '../components/Seo'
import NkoForm from '../components/NkoForm';
import Footer from '../components/Footer';

const nkoTemplate = props => {
  const { title, description, image, slug, hiLight} = props.data.nko
  const cards = props.data.nko.cards

  const seo = {
    title,
    slug,
    description,
    image,
	}
  return <Layout currentSlug={'/' + slug}>
      <Seo {...seo} />
      <div className="l-section l-section--nko">
        {/* TODO Move to lesson component */}
        {/* <h1>{title}</h1> */}
        <LessonCard title={title} hiLight={hiLight} image={image} isHeaderCard={true}  isNkoHeaderCard={true} />
        <ul className="b-lessons__list h-list-unstyled b-lessons__list--nko" >
          {cards && cards.map(card => (
            <li
              key={card.id}
              className="b-lessons__list-item b-lessons__list-item"
            >
              <LessonCard {...card} library={true} isNkoCard={true}/>
            </li>
          ))}
        </ul>
        <NkoForm />
      </div>
      <Footer />
    </Layout>
}

export default nkoTemplate

export const pageQuery = graphql`
  query nkoQuery($id: String!) {
    nko: contentfulNko(id: { eq: $id }) {
      title
      description
      hiLight
      slug
      cards {
        id
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      image {
        id
        file {
          url
          fileName
          contentType
        }
        fluid {
          aspectRatio
          base64
          src
          sizes
          srcSet
          srcWebp
          srcSetWebp
        }
      }
      # mediaLibraryButtonText
      # mediaLibraryLink{
      #   slug
      # } 
      # videoLibraryButtonText
      # videoLibraryLink {
      #   slug
      # }
    }
  }
`
